import styled from "styled-components";
import { Highlight } from "../../components/Hightlight/Hightlight.style";
import { Link as muiLink } from "@mui/material";

export const PrivacyPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 20px;
  margin-bottom: 49px;
  background-color: ${(props) => props?.theme.colors.white};

  padding: 40px 32px 64px;
  font-size: 18px;
  border-radius: 16px;
  font-weight: 300;
  line-height: 24px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
    padding: 16px;
    font-size: 16px;
  }
`;

export const List = styled.ul``;
export const ListItem = styled.li``;
export const Link = styled(muiLink)`
  color: ${(props) => props?.theme.colors.secondaryOpaque};
`;

export const PrivacyPolicyParagrafContent = styled.div`
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  ${Highlight} {
    font-size: 28px;
    line-height: 28px;
    margin: 32px 0 0;
    padding: 4px 8px;
  }

  @media screen and (max-width: 1200px) {
    ${Highlight} {
      font-size: 24px;
      line-height: 24px;
      margin: 32px 0 0;
      padding: 4px 8px;
    }
  }
`;

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { LogoContainer, LogoImage } from "./Logo.styled";
import logo from "../../assets/images/png/Tremium_02-m.png";
import logoLight from "../../assets/images/png/logo-tremium-light.svg";
//Import logo

const Logo = forwardRef((props, ref) => {
  return (
    <LogoContainer ref={ref}>
      <LogoImage
        src={props?.light ? logoLight : logo}
        height={props.height}
        width={props.width}
      />
    </LogoContainer>
  );
});

Logo.displayName = "Logo";

Logo.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  light: PropTypes.bool,
};

Logo.defaultProps = {
  light: false,
};

export default Logo;

export default {
  app: {
    title: "React template",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    language: "Jezik",
    english: "Engleski",
    serbian: "Srpski",
    dataGridExample: "Primer Data Grid-a",
    close: "Close",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavite",
    labelUsername: "Korisničko ime",
    labelEmail: "E-mail",
    labelPassword: "Šifra",
    next: "Napred",
    nextPage: "Sledeća stranica",
    previousPage: "Predhodna stranica",
    back: "Nazad",
    goBack: "Idite nazad",
    ok: "U redu",
    done: "Gotovo",
    confirm: "Potvrdite",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} do {{end}}",
    },
    logout: "Izloguj se",
    seeMore: "Vidi još",
  },

  notifications: {
    title: "Obaveštenja",
  },
  pages: {
    home: "Početna",
    login: "Login",
    register: "Register",
    forgotPassword: "Zaboravljena lozinka",
    error: "Pogrešna stranica",
    notFound: "Nije pronađena stranica",
    profile: "Profilna stranica",
    settings: "Podešavanja",
    contact: "Kontakt",
    dashboard: "Komandna tabla",
    about: "O nama",
    careers: "Posao",
  },
  register: {
    registerTitle: "Register",
    usernameRequired: "Username is required.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordRequired: "A Password is required.",
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Nemate nalog? ",
    emailFormat: "Loš format email-a",
    emailRequired: "Email/korisničko ime je obavezno",
    noUsers: "Ne postoji korisnik",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Registrujte se",
    email: "Please enter your email address or username to log in:",
    logInTitle: "Prijava",
    logIn: "Ulogujte se",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Zaboravili ste šifru?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  date: {
    timespan: {
      yearsAgo: "Pre {{years}} godine",
      monthsAgo: "Pre {{months}} meseca",
      daysAgo: "Pre {{days}} dana",
      hoursAgo: "Pre {{hours}} sata",
      minutesAgo: "Pre {{minutes}} minuta",
      secondsAgo: "Pre {{seconds}} sekunde",
      now: "Upravo sada",
    },
  },
  dashboard: {
    charts: {
      lineChart: "Linijski grafikon",
      pieChart: "Kružni grafikon",
      barChart: "Dijagram",
      radarChart: "Radarski grafikon",
    },
  },
  profile: {
    image: "Slika profila",
    chooseImage: "Izaberi sliku za profil",
    form: {
      step1: "Lični podaci",
      step2: "Adresa",
      step3: "Posao",
    },
    labels: {
      firstName: "Ime",
      lastName: "Prezime",
      gender: "Pol",
      city: "Grad",
      street: "Ulica",
      postalCode: "Poštanski broj",
      companyName: "Ime firme",
      companyAddress: "Adresa firme",
      yearsInCompany: "Broj godina u firmi",
    },
  },
  pageTitles: {
    Home: "Home Page | Tremium Software",
    About: "About Us | Tremium Software",
    Career: "Career Page | Tremium Software",
  },
  homePage: {
    intro: {
      header: "Developing Software <0>Boldly Beyond</0> <0>Boundaries</0>",
      text: "We are a team of IT professionals who <0>boldly</0> bring ideas to life by creating innovative digital platforms and offering excellent technical support.",
    },
    paragraf1: {
      header: "Our Services Lineup",
      items: {
        item1: {
          header: "<1>Software</1> <1>Development <0>Outsourcing<0></1>",
          text: "We develop <0>cutting-edge custom software</0> applications, mobile apps, or websites for our partners, providing them with sustainable and adaptable digital solutions.",
        },
        item2: {
          header: "Software Testing & <0>QA</0>",
          text: "We provide a <0>complete range of software testing</0> services while keeping partners' satisfaction at its core. You can be sure your product meets high-quality standards.",
        },
        item3: {
          header: "<1>Web</1> <1><0>Development</0></1>",
          text: "We help you <0>streamline business</0> processes while leveraging reliable and secure development approaches. We create fully functional and visually appealing digital solutions. ",
        },
        item4: {
          header: "Mobile <0>Development</0>",
          text: "Our mobile app development expertise can offer you <0>full life cycle</0> management for iOS, Android, and Hybrid-based applications from prototyping to deployment and testing.",
        },
        item5: {
          header: "<1>DevOps</1>",
          text: "We have the technical abilities and knowledge to assist your team in transitioning to a <0>DevOps development and delivery</0> strategy. We write the scripts required to automate the workflow and offer security and performance suggestions and implementation.",
        },
        item6: {
          header: "<1>IT</1> <1><0>Consulting</0> Service</1>",
          text: "We provide <0>expert advice and strategic guidance</0> on IT-related decisions, including technology adoption, system upgrades, data analyses, business analyses, and digital transformation initiatives.",
        },
        item7: {
          header: "IT <br />Project <0>Management</0>",
          text: "Our Project Managers coordinate IT projects from <0>inception to completion</0>, ensuring they are delivered on time, within budget, and according to specifications.",
        },
        item8: {
          header: "<1><0>AI</0></1> <1>Development</1>",
          text: "Our team leverages <0>advanced machine learning</0> algorithms and neural networks to create scalable AI applications.",
        },
        item9: {
          header: "<1><0>Power BI</0><1/>",
          text: "Unlock the power of your data with captivating visuals! <0>Our team specializes</0> in leveraging Power BI to craft interactive reports that transform raw data into meaningful insights.",
        },
        item10: {
          header: "<1>Azure Data</1> <1><0>Factory</0></1>",
          text: "Our expertise lies in utilizing Azure Data Factory as a <0>sophisticated tool</0> for defining ETL workflows with precision and expertise, thereby harnessing its full potential for streamlined data integration.",
        },
      },
    },
    paragraf2: {
      header: "Expert Tech Stack",
    },
    paragraf3: {
      header: "Industries We Excell At",
      items: {
        item1: "Fintech",
        item2: "Healthcare",
        item3: "Trading",
        item4: "Transport",
        item5: "Booking",
      },
    },
    paragraf4: {
      header: "Agile Process That Defines Us",
      text: `“Agile methodology is an iterative approach to software development that emphasizes flexibility, collaboration, and continuous improvement.”`,
      items: {
        item1: {
          header: "Vision Crafting",
          text: "We create Personas, Journey Mapping, and Usability Testing to understand your user base better.",
        },
        item2: {
          header: "Innovation Blueprinting",
          text: "Our design team creates system flows, journey maps, annotated wireframes, final UI designs, and click-model prototypes.",
        },
        item3: {
          header: "Code Artistry",
          text: "After deciding on a strategy, we proceed with secure frontend, backend, and full-stack development.",
        },
        item4: {
          header: "QA Performance",
          text: "Our code is scalable, reusable, readable, and high-performing.",
        },
        item5: {
          header: "Launch Elevation",
          text: "We are prepared to launch after all sprints have ended and User Acceptance Testing (UAT) has been successful.",
        },
      },
    },
    paragraf5: {
      header: "Something from Our Clients",
      items: {
        item1: {
          persion: {
            name: "Peter Custer",
            position: "CTO at GBI",
          },
          text: "The team at Tremium is knowledgeable, experienced, and just as importantly, dependable and engaged. They've always been there when I've needed them, never leaving me stranded (even nights and weekends when necessary). They are a group of professionals who really know their stuff. My relationship with them spans over 12 years, during which I've come to rely on their dedication, support, and wealth of expertise. I can recommend them without any hesitation.",
        },
        item2: {
          persion: {
            name: "Saša Milosavljević",
            position: "Manager at BP Consulting",
          },
          text: "We have been fostering a friendship with the Tremium team for a long time, which we strengthened by working together on a fantastic project. The idea for the project came from our desire to simplify day-to-day tasks. The Tremium team took that idea and improved it with their knowledge and skills to create a feature-rich and truly helpful application. We highly recommend this team of professionals!",
        },
        item3: {
          persion: {
            name: "Božidar Ignjatović",
            position: "CEO at Badin Soft",
          },
          text: "Velja and Tripko are my old and reliable partners. We've been working together for more than a decade. Highly skilled professionals for any assigned job. Do not hesitate if you need support, Tremium brings additional value. They are not only a tech team, they'll be partners to your business as they were for ours!",
        },
        item4: {
          persion: {
            name: "Adis Terzić",
            position: "Niš Ekspres",
          },
          text: 'Tremium team helped us to build and integrate several parts of the existing Information System with multiple integration points and enabled fieldwork. In the work process, they come forward with comments and questions that eventually bring additional value to the product. Be assured that you can always count on the highest performance from Tremium, "around-the-clock" support, and have a partner for your business.',
        },
        item5: {
          persion: {
            name: "Jimmie Antonsson",
            position: "Project Owner at Travel Support",
          },
          text: "We are really satisfied with the high-quality relationship that we have built with the Tremium team through the years. They have helped us optimize and realize important projects with their ideas, dedication, and committed work. With them, we can say with confidence that the entire process runs smoothly, from idea to execution.",
        },
        item6: {
          persion: {
            name: "Asseco team",
          },
          text: "The Tremium team provided invaluable assistance during a crucial moment, guaranteeing the effectiveness of a project of considerable importance. Through their proactive approach and bold ideas, they contributed to the conceptual solution of the project itself. It is our pleasure to collaborate with such a team of professionals!",
        },
      },
    },
    paragraf6: {
      header: "Explore our Domains",
    },
  },
  aboutPage: {
    title: "Boldness <br /> <0>Beyond Boundaries</0>",
    intro:
      "By the end of 2023, we began on an exciting journey and founded Tremium Software with the aim and purpose of building a team of professionals who will boldly embark on the development of innovative ideas. The result is a business that fosters each employee's <0>potential for growth</0>, places a strong emphasis on building enduring relationships with clients and family values, and works tirelessly to have a positive impact on the community.",
    Mission1Title: "Our <0>Mission</0> and <1>Promise</1>",
    Mission2Title: "We <0>Stand For</0>",
    mission1:
      "Our mission is to create <0>lasting partnerships</0> with clients through innovative digital solutions, prioritizing deep understanding and tailored excellence. We build relationships based on trust, transparency, and effective communication, fostering a collaborative and creative work culture for our colleagues.",
    mission2:
      "Envisioning ourselves as a leading local IT company, we aim to set industry standards and actively contribute to developing the local and global IT community. We aspire to be a <0>socially responsible</0> entity, leveraging technology to address societal challenges and leaving a meaningful legacy through continuous innovation, ethical practices, and community engagement. Our overarching goal is to positively contribute to the well-being of the communities we serve.",
    commitmentTitle: "We <0>Stand For</0>",
    commitment1Title: "Long - standing relationship",
    commitment1:
      "Culture that cultivates trust, understanding, loyalty, open communication, and contribution to create relationships that stand the test of time",
    commitment2Title: "Striving for <0></0>excellence",
    commitment2:
      "Culture of courage, risk-taking, and pushing beyond boundaries through creative thinking in processes, ideas, organizational structure, and corporate culture.",
    commitment3Title: "Boldness",
    commitment3:
      "Culture of curiosity, adaptability, and a willingness to invest in the growth of individuals.",
    commitment4Title: "Committed to people",
    commitment4:
      "Culture of respect, diversity, and teamwork, where each of us feels valued and has the opportunity to reach our full potential.",
    ourTeam: {
      Core: {
        Title: "Our <0>Core</0> Team",
        Velimir: "Velimir Đorđević",
        Milos: "Miloš Mandić",
        Milan: "Milan Tripković",
        Role: "CO-FOUNDER",
      },
      Business: {
        Title: "Our <0>Business</0> Partners",
        Milan: "Milan Đordjević",
        Miljan: "Miljan Stefanović",
        Marko: "Marko Đurović",
        Strahinja: "Strahinja Đurković",
        Dunja: "Dunja Stevanović",
        Role: "Business Partner & Technical Team Lead",
      },
      People: {
        Title: "Our <0>People</0> Team",
        Vesna: {
          Name: "Vesna Kostić",
          Role: "HR Manager",
        },
        SaraMancic: {
          Name: "Sara Mančić",
          Role: "Employer Branding & Marketing Manager",
        },
        Dusan: {
          Name: "Dušan Dimić",
          Role: "Legal & Finance Manager",
        },
      },
      Technical: {
        Title: "Our Tech Team",
      },
    },
  },
  careersPage: {
    intro: {
      header: "Work (Delight) @ <0>Tremium</0>",
      text: "We are <0>a team</0> of IT professionals who strive to build a caring, bold, and powerful culture that makes a difference.",
    },
    paragraf1: {
      header: "Benefits",
      card1: {
        header: "Bread and Butter",
        text: `<ul>
                  <li>Flexible working hours</li>
                  <li>Hybrid working environment</li>
                  <li>Modern office</li>
                  <li>Competitive financial conditions</li>
                  <li>Lunch</li>
              </ul>`,
      },
      card2: {
        header: "Backbone",
        text: `<ul> 
                  <li>Private health insurance</li>
                  <li>Sick day</li>
                  <li>Additional free days per seniority</li>
                  <li>Childbirth bonuses</li>
                  <li>Wedding bonuses</li>
              </ul>`,
      },
      card3: {
        header: "Lifeblood",
        text: `<ul>
                  <li>Mentorship programs</li>
                  <li>Career development program</li>
                  <li>Educational meetups</li>
                  <li>Conferences & events (both as a participant and speaker)</li>
                  <li>Soft skills training</li>
                  <li>Books & e-books</li>
              </ul>`,
      },
      card4: {
        header: "Driving force",
        text: `<ul>
                  <li>Team-building trip</li>
                  <li>Themed parties</li>
                  <li>Weekly team-building activities</li>
                  <li>Family activities</li>
              </ul>`,
      },
    },
    paragraf2: {
      header: "Culture",
      items: {
        item1:
          "Our bold dedication lies in providing a supportive and creative atmosphere where every idea and opinion is valued. We developed a <0>unique career</0> development program for our colleagues to create an environment where they can learn, grow, and progress professionally. From the beginning, you can expect to find a balance between educational support, quality progress, and team-building activities.",
        item2:
          "We are particularly committed to strengthening family values because we are aware that the stud family is a support for all our colleagues. That is the reason we have developed a unique program of <0>family-friendly</0> events and benefits. We have provided special bonuses for wedding days, childbirth, and starting school season. A few times a year we organize family trips, climbing, and skiing. Holiday celebrations with the youngest members of the Tremium team are the most enjoyable parties in Tremium Software.",
        item3:
          "We place a high value on mental health care, which is why our team of professionals plans events that provide the ideal balance between professional and personal life. We encourage teamwork wherever possible, which also makes it essential for us to provide monthly events for team building and team bonding. Our team gets to engage in <0>various activities</0> during the workweek, such as playing darts or pool, getting together for a sing-along with a guitar, visiting cinemas, and enjoying delicious food at Niš kafana.",
      },
    },
    paragraf3: {
      header: "<0>Selection</0> Process",
      items: {
        item1: {
          header: "Application Submission",
          text: "This is where the adventure begins! If you believe that Tremium Software is the place where you can enjoy the adventure, we are more than trill to meet you. All of our open positions are listed on our website. Be bold and send us your resume.<br/><br/>Need more information? Contact us!",
        },
        item2: {
          header: "Resume Review",
          text: "Your resume will be reviewed by our HR department and the team leader of the position you are applying for. After that, we'll send you an email with an explanation of the next round of selection. Your interview will be held online or in person at our office.",
        },
        item3: {
          header: "HR Interview",
          text: "Let's get to know each other. During your interview with the recruiter, we will provide you with a quick overview of our company, culture, and work environment. Along with enhancing your professional career, we will talk about your past employment history, interests, and goals. We will also answer any questions you may have for us.",
        },
        item4: {
          header: "Technical Interview",
          text: "The next step of our process is a technical interview. Depending on the role, you will answer questions about the technology you worked on, complete a task, and have a conversation with our experienced engineers about your project experiences to better evaluate your level of technical skill.",
        },
        item5: {
          header: "Feedback & Offer",
          text: "The last round in our selection process is feedback. You'll get general feedback on your interview. And, of course, if the outcome is positive, we will give you an offer with detailed information about working conditions.<br /><br />We are excited to meet you! ",
        },
      },
    },
    paragraf4: {
      header: "Open <0>Positions</0>",
      text: "You can send your CV or contact us for any questions at <0>hr@tremium.net</0>.<br /><br />We are more than thrilled to meet you!",
    },
  },
  privacyPolicyPage: {
    title: "Privacy Policy <br /> for <0>Tremium</0>",
    introText: `<p>Tremium doo is dedicated to adhering to data protection regulations, including those outlined in the <i>General Data Protection Regulation</i> (EU Regulation 2016/679). This privacy policy outlines the personal data we gather, how we utilize it, and the rights you possess regarding your information.</p>`,
    sectionIntroduction: {
      title: "Introduction",
      text: `<p>We highly value your privacy and are committed to safeguarding it through compliance with this <i>Privacy Policy</i>. This <i>Privacy Policy</i> delineates the types of data we may collect from you or that you provide, such as when you visit our websites or interact with us through other means, and how we utilize, protect, and disclose that information.</p>
             <p>This <i>Privacy Policy</i> does not extend to data collected on third-party platforms or applications, including advertisements, that may link to or be accessible from our platforms. We are not accountable for the privacy policies or practices concerning data collection, use, and disclosure on those platforms. We urge you to review the privacy policies of each platform you engage with.</p>
             <p>Kindly peruse this <i>Privacy Policy</i> meticulously to comprehend our policies and procedures regarding your information and how we intend to handle it. By accessing or utilizing any of our services, including our websites and email communications, you agree to this Privacy Policy. This Policy is subject to periodic changes, which may influence how we utilize the information described below. Hence, please revisit the Privacy Policy periodically for updates.</p>
              `,
    },
    sectionHowWeUtilizeAndSafeguardPersonalInformation: {
      title: "How We Utilize and Safeguard Personal Information",
      text: `<p>We collect various types of information for our business operations, including but not limited to:</p>
            <1>
              <2><strong>Information provided by you</strong>: When you interact with our platforms or communicate with us, we may request or you may voluntarily provide certain information, including personally identifiable information such as name, surname, email address, and telephone number. </2>
              <2><strong>Information collected from website forms</strong>: This encompasses information submitted when registering to use segments of our platforms or our products and services, posting content, or requesting additional services. We may solicit information when you report issues with our platforms, products, or services.</2>
              <2><strong>Usage details, IP addresses, and cookies</strong>: As you navigate and engage with our platforms, we may automatically gather specific information about your devices, browsing activities, and patterns using common internet technologies like cookies and web beacons. This may include details of your visits to our platforms, connectivity information such as IP addresses and browser details, geographical data, logs, other communication data, and resources accessed on the platforms. This information aids us in enhancing our platforms and delivering superior and more personalized content and services by enabling us to gauge audience size and usage trends and recognize returning users.</2>
              <2><strong>General business-related information</strong>: We may also receive other personal data from or concerning you as part of our routine business operations.</2>
            </1>
            
            <p>We utilize the information we collect about you or that you provide to us, including personal information, for the following purposes:</p>
            <1>
              <2>Presenting our platforms, products, and services to you and continually enhancing them.</2>
              <2>Furnishing you with information, products, or services that you request from us.</2>
              <2>Notifying you about changes to our platforms, products, or services, including new offerings, promotions, or events.</2>
              <2>Upholding the integrity and security of our platforms, products, and services.</2>
              <2>Facilitating our standard business operations, including human resources, recruitment, business research, and outreach efforts.</2>
            </1>`,
    },
    sectionLegalBasisforDataProcessing: {
      title: "Legal Basis for Data Processing",
      text: `<p>We are only authorized to process personal data if we have a valid legal justification. Consequently, we will only process your data if:</p>
              <1>
                <2>The processing is indispensable to fulfill our contractual obligations to you or to take pre-contractual measures at your behest, such as authenticating your login to our services or ensuring your access is limited to the services and data agreed upon.</2>
                <2>The processing is necessary to adhere to our legal or regulatory obligations, such as tax reporting or regulatory mandates.</2>
                <2>The processing is imperative for the legitimate interests of Tremium doo and does not unduly impinge upon your interests or fundamental rights and freedoms.</2>
                <2>The processing is necessary for the performance of a task carried out in the public interest; or, in certain cases and as requested from time to time, we have obtained explicit consent from you.</2>
              </1>
              <p>If we process any special categories of data concerning you, we do so because:</p>
              <1>
                <2>The data has been explicitly made public.</2>
                <2>The processing is vital for establishing, exercising, or defending a legal claim.</2>
                <2>The processing is crucial for reasons of substantial public interest, or you have explicitly consented to us processing that information (where legally permissible).</2>
              </1>`,
    },
    sectionDisclosureOfYourInformation: {
      title: "Disclosure of Your Information",
      text: `<p>Safeguarding your personal information is paramount to us, and we neither lease nor sell your data to any entity. We may, however, disclose or transfer personal information collected or provided by you under the circumstances outlined in this Privacy Policy, including:</p>
            <1>
              <2>To a purchaser or other successor in the event of a merger, acquisition, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Tremium doo assets, where personal information held by Tremium doo about users of our platforms is included among the assets transferred.</2>
              <2>To our affiliates, agents, contractors, service providers, and other third parties we engage with, who are contractually bound to maintain the confidentiality of personal information and utilize it solely for providing services to or with us.</2>
              <2>To comply with any court order, law, or legal process, including responding to government or regulatory requests.</2>
              <2>To safeguard the rights, property, or security of Tremium doo, our employees, our users, or others.</2>
            </1>`,
    },
    sectionOnwardTransferOfYourInformation: {
      title: "Onward Transfer of Your Information",
      text: `<p>Temium doo is headquartered in Serbia and does not have subsidiaries or affiliates outside of Serbia. We process and store your personal data within Serbia and do not transfer it to entities outside the country unless required by law or with your explicit consent.</p>
            <p>If, in exceptional cases, an international transfer of personal data is necessary, we ensure that appropriate safeguards are in place in accordance with applicable data protection laws. You may request further information regarding such safeguards by exercising your rights as described below.</p>`,
    },
    sectionDataIntegrityAndProportionality: {
      title: "Data Integrity and Proportionality",
      text: `<p> We only collect and retain the amount of personal information necessary for specific, identified purposes as described in this Privacy Policy, and we do not utilize it in any manner incompatible with those purposes.</p>`,
    },
    sectionYourRightsAndHowToExerciseThem: {
      title: "Your Rights and How to Exercise Them",
      text: `<p>You may possess the right to access and obtain a copy of your personal data as processed by Tremium doo. If you believe any information we hold about you is inaccurate or incomplete, you may also request correction of your personal data.</p>
             <p>You may also have the right to:</p>
             <1>
                <2>Object to the processing of your personal data.</2>
                <2>Request the erasure of your personal data.</2>
                <2>Request restrictions on the processing of your personal data; and/or</2>
                <2>Withdraw consent where Tremium doo obtained your consent to process personal data (without affecting the lawfulness of any processing carried out before withdrawal).</2>
             </1>
            <p>We will honor such requests, withdrawals, or objections as applicable data protection regulations mandate. However, these rights are not absolute; they do not invariably apply, and exemptions may be invoked. Typically, we will ask you to verify your identity and/or provide additional information to understand your request better. If we fail to comply with your request, we will explain.</p>
            <p>To exercise the aforementioned rights, you may email us at <strong><3 href="mailto:marketing@tremium.net">marketing@tremium.net</3></strong>. If you are dissatisfied with our handling of your personal data, kindly inform us, and we will investigate your concern.</p>`,
    },
    sectionSecurity: {
      title: "Security",
      text: `<p>We prioritize your security and implement reasonable measures to protect and secure your personal information from unauthorized access, use, and disclosure. We have established adequate technical and organizational safeguards to shield personal data against unauthorized or unlawful destruction, loss, alteration, misuse, disclosure, access, and other unlawful forms of processing. These security measures are implemented considering the state of technology, implementation costs, processing risks, and the nature of the personal data, with particular emphasis on sensitive data.</p>
             <p>Your information's safety and security also rely on you. If you have been provided (or have chosen) a password to access certain sections of our platforms, products, or services, it is your responsibility to keep this password confidential. Kindly refrain from sharing your password with anyone.</p>
             <p>While we strive to maintain the security of information transmitted to us over the Internet, we cannot guarantee or warrant the security of any information you transmit to us. We are not liable for circumvention of any privacy settings or security measures embedded in our platforms.</p>
             <p>If you remain dissatisfied with our response, you possess the right to file a complaint with the data protection authority in your jurisdiction or the jurisdiction where you reside or work, or where you believe a data-related issue has emerged.</p>`,
    },
    sectionDataRetention: {
      title: "Data Retention",
      text: `<p>We will retain personal data only for the duration necessary to fulfill the purposes for which it was collected or to comply with legal, regulatory, or internal policy obligations.</p>`,
    },
    sectionChangesToOurPrivacyPolicy: {
      title: "Changes to Our Privacy Policy",
      text: `<p>We reserve the right to amend this Privacy Policy periodically. We urge you to revisit this page regularly to review the prevailing version of this Policy. If you have any concerns or inquiries about this Policy or wish to file a complaint, kindly contact us.</p>`,
    },
    sectionContactInformation: {
      title: "Contact Information",
      text: `<p>For any queries or remarks regarding this Privacy Policy, please contact us by emailing <strong><3 href="mailto:marketing@tremium.net">marketing@tremium.net</3></strong> or writing to us at:</p>
              <p>
              <strong>
                Božidarčeva 16, VP1<br />
                Niš, Serbia
              </strong>
              </p>
              <p>We value your privacy and are committed to addressing any concerns you may have regarding the handling of your personal information.</p>`,
    },
  },
};

import i18n from "../i18nt/index";

// export const BASE_PAGE = "/";
// export const LOGIN_PAGE = "/login";
// export const REGISTER_PAGE = "/register";
// export const FORGOT_PASSWORD_PAGE = "/forgot-password";
// export const HOME_PAGE = "/";
// export const ERROR_PAGE = "/error-page";
// export const NOT_FOUND_PAGE = "/not-found";
export const AUTH_CALLBACK_PAGE = "/api/auth/:provider/callback";

export const PAGES = {
  BASE: {
    route: "/",
    title: i18n.t("pages.home"),
  },
  HOME: {
    route: "/home/*",
    title: i18n.t("pages.home"),
  },
  REGISTER: {
    route: "/register/*",
    title: i18n.t("pages.register"),
    nestedRoutes: {
      FIRST_STEP: {
        route: "/register/first-step/*",
        title: i18n.t("pages.register.nestedRoutes.firstStep"),
      },
      SECOND_STEP: {
        route: "/register/second-step/*",
      },
    },
  },
  LOGIN: {
    route: "/login/*",
    title: i18n.t("pages.login"),
  },
  FORGOT_PASSWORD: {
    route: "/forgot-password/*",
    title: i18n.t("pages.forgotPassword"),
  },
  ERROR: {
    route: "/error-page",
    title: i18n.t("pages.error"),
  },
  NOT_FOUND: {
    route: "/not-found",
    title: i18n.t("pages.notFound"),
  },
  PROFILE: {
    route: "/profile",
    title: i18n.t("pages.profile"),
  },
  SETTINGS: {
    route: "/settings",
    title: i18n.t("pages.settings"),
  },
  CAREERS: {
    route: "/careers",
    title: i18n.t("pages.careers"),
  },
  CONTACT: {
    route: "footer",
    title: i18n.t("pages.contact"),
  },
  ABOUT: {
    route: "/about-us",
    title: i18n.t("pages.about"),
  },
  PRIVACY_POLICY: {
    route: "/privacy-policy",
    title: i18n.t("pages.about"),
  },
};

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { PrivacyPolicyContainer } from "./PrivacyPolicyPage.styled";
import Section from "../../components/Section/Section";
import { TitleHighlight } from "components/Section/Section.styled";
import { PrivacyPolicyParagraf } from "./PrivacyPolicyParagraf";
//import i18next from "i18next";
import useDevice from "hooks/useDevice";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return (
    <>
      <Section
        isStyled={true}
        bgColor="dark"
        titleAlignLeft
        cardTitle={
          <Trans
            t={t}
            components={[<TitleHighlight isStyled={true} key={0} />]}
            i18nKey="privacyPolicyPage.title"
          />
        }
        margin={isMobile ? "93px 0px 32px" : "170px 10px 39px"}
        paddingBottom={isMobile ? "10px" : "24px"}
      >
        <PrivacyPolicyContainer>
          <PrivacyPolicyParagraf text={"privacyPolicyPage.introText"} />
          <PrivacyPolicyParagraf
            title={"privacyPolicyPage.sectionIntroduction.title"}
            text={"privacyPolicyPage.sectionIntroduction.text"}
          />
          <PrivacyPolicyParagraf
            title={
              "privacyPolicyPage.sectionHowWeUtilizeAndSafeguardPersonalInformation.title"
            }
            text={
              "privacyPolicyPage.sectionHowWeUtilizeAndSafeguardPersonalInformation.text"
            }
          />
          <PrivacyPolicyParagraf
            title={"privacyPolicyPage.sectionLegalBasisforDataProcessing.title"}
            text={"privacyPolicyPage.sectionLegalBasisforDataProcessing.text"}
          />
          <PrivacyPolicyParagraf
            title={"privacyPolicyPage.sectionDisclosureOfYourInformation.title"}
            text={"privacyPolicyPage.sectionDisclosureOfYourInformation.text"}
          />
          <PrivacyPolicyParagraf
            title={
              "privacyPolicyPage.sectionOnwardTransferOfYourInformation.title"
            }
            text={
              "privacyPolicyPage.sectionOnwardTransferOfYourInformation.text"
            }
          />
          <PrivacyPolicyParagraf
            title={
              "privacyPolicyPage.sectionDataIntegrityAndProportionality.title"
            }
            text={
              "privacyPolicyPage.sectionDataIntegrityAndProportionality.text"
            }
          />
          <PrivacyPolicyParagraf
            title={
              "privacyPolicyPage.sectionYourRightsAndHowToExerciseThem.title"
            }
            text={
              "privacyPolicyPage.sectionYourRightsAndHowToExerciseThem.text"
            }
          />
          <PrivacyPolicyParagraf
            title={"privacyPolicyPage.sectionSecurity.title"}
            text={"privacyPolicyPage.sectionSecurity.text"}
          />
          <PrivacyPolicyParagraf
            title={"privacyPolicyPage.sectionDataRetention.title"}
            text={"privacyPolicyPage.sectionDataRetention.text"}
          />
          <PrivacyPolicyParagraf
            title={"privacyPolicyPage.sectionChangesToOurPrivacyPolicy.title"}
            text={"privacyPolicyPage.sectionChangesToOurPrivacyPolicy.text"}
          />
          <PrivacyPolicyParagraf
            title={"privacyPolicyPage.sectionContactInformation.title"}
            text={"privacyPolicyPage.sectionContactInformation.text"}
          />
        </PrivacyPolicyContainer>
      </Section>
    </>
  );
};

export default PrivacyPolicyPage;

import styled from "styled-components";
import { Box } from "@mui/material";
import { CardContainer } from "components/Cards/ClientCard/ClientCard.styled";

export const HomeOurClientsContainer = styled(Box)`
  display: flex;
  position: relative;
  background: transparent;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  margin-bottom: 160px;

  @media (max-width: 959px) {
    overflow-x: auto;
  }
`;

export const HomeOurClientsCards = styled(Box)`
  display: flex;
  width: 3510px;

  & ${CardContainer}:last-child {
    margin-right: auto;
  }

  @media (max-width: 1024px) {
    width: 1660px;
  }
`;

export const HomeOurClientsBtn = styled(Box)`
  background-color: ${(props) => props?.theme.colors.secondaryOpaque};
  border-radius: 50%;
  padding: 16px;
  margin-left: 10px;
  height: 61px;
  width: 61px;

  cursor: pointer;
`;
export const HomeOurClientsBtnBox = styled(Box)`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
`;

export const HomeOurClientsFade = styled(Box)`
  width: calc(100% + 10px);
  position: relative;
  background: linear-gradient(
    90deg,
    ${(props) => props?.theme.colors.dark},
    transparent 3%,
    transparent 90%,
    ${(props) => props?.theme.colors.dark}
  );
  top: 0;
  height: 374px;
  z-index: 1050;

  margin-top: -374px;
  margin-left: -10px;
  @media (max-width: 768px) {
    display: none;
  }
`;

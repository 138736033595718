import ReactGA from "react-ga4";

export const initGA = () => {
  const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || "";
  if (gaMeasurementId) {
    ReactGA.initialize(gaMeasurementId);
  } else {
    console.error("GA Measurement ID is not defined!");
  }
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const trackEvent = (category, action, label = undefined) => {
  ReactGA.event({ category, action, label });
};

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  Link,
  PrivacyPolicyParagrafContent,
} from "./PrivacyPolicyPage.styled";
import { Highlight } from "../../components/Hightlight/Hightlight.style";

export const PrivacyPolicyParagraf = ({ title, text }) => {
  const { t } = useTranslation();
  return (
    <PrivacyPolicyParagrafContent>
      {title && (
        <Highlight color="thertiary" isText={true}>
          {t(title)}
        </Highlight>
      )}
      <Trans
        t={t}
        i18nKey={text}
        components={{
          1: <List />,
          2: <ListItem></ListItem>,
          3: <Link />,
        }}
      />
    </PrivacyPolicyParagrafContent>
  );
};

PrivacyPolicyParagraf.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

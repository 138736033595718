import React, { useEffect } from "react";
import Section from "components/Section/Section";
import { Trans, useTranslation } from "react-i18next";
import { TitleHighlight } from "components/Section/Section.styled";
import HomeBanner from "components/DataComponents/HomeBanner/HomeBanner";
import HomeOurClients from "components/DataComponents/HomeOurClients/HomeOurClients";
import ScrollingBanner from "components/ScrollingBanner/ScrollingBanner";
import HomeAgileProcess from "components/DataComponents/HomeAgileProcess/HomeAgileProcess";
import useDevice from "hooks/useDevice";
import {
  ImageWithTitle,
  ImageTitle,
  ImageWithTitleBox,
  HomeOurClientsLogos,
  HomeOurClientsLogo,
} from "./HomePage.styled";
import { Box } from "@mui/material";

//Expert Tech Stack
import imgTechStack1 from "assets/images/pages/home/tech-stack/angular.png";
import imgTechStack2 from "assets/images/pages/home/tech-stack/python.png";
import imgTechStack3 from "assets/images/pages/home/tech-stack/javascript.svg";
import imgTechStack4 from "assets/images/pages/home/tech-stack/asp.net.png";
import imgTechStack5 from "assets/images/pages/home/tech-stack/react.svg";
import imgTechStack6 from "assets/images/pages/home/tech-stack/java.svg";
import imgTechStack7 from "assets/images/pages/home/tech-stack/unity.png";

//Clients
import imgClients1 from "assets/images/pages/home/clients/profitoptics.png";
import imgClients1sm from "assets/images/pages/home/clients/profitoptics_sm.png";
import imgClients2 from "assets/images/pages/home/clients/awerolab.png";
import imgClients3 from "assets/images/pages/home/clients/nisekspres.png";
import imgClients4 from "assets/images/pages/home/clients/badinsoft.png";
import imgClients5 from "assets/images/pages/home/clients/gbi.png";
import imgClients6 from "assets/images/pages/home/clients/asseco.png";
import imgClients7 from "assets/images/pages/home/clients/Medulla.png";
import imgClients8 from "assets/images/pages/home/clients/DualSOFT.png";
import i18next from "i18next";
import GoogleMap from "components/GoogleMaps/GoogleMap";

//Industries
import imgIndustries1 from "assets/images/pages/home/industries/fintech.jpg";
import imgIndustries2 from "assets/images/pages/home/industries/healthcare.jpg";
import imgIndustries3 from "assets/images/pages/home/industries/Trading.jpg";
import imgIndustries4 from "assets/images/pages/home/industries/transport.jpg";
import imgIndustries5 from "assets/images/pages/home/industries/booking.jpg";
import ServiceLineUp from "components/DataComponents/ServiceLineUp/ServiceLineUp";

const HomePage = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDevice();

  useEffect(() => {
    document.title = i18next.t("pageTitles.Home");
  }, []);

  const homeExpertTechStack = [
    imgTechStack1,
    imgTechStack2,
    imgTechStack3,
    imgTechStack4,
    imgTechStack5,
    imgTechStack6,
    imgTechStack7,
  ];

  const homeClients = [
    imgClients1,
    imgClients2,
    imgClients3,
    imgClients4,
    imgClients5,
    imgClients6,
    imgClients7,
    imgClients8,
  ];

  const homeClientsSm = [
    {
      src: imgClients1sm,
      width: "62px",
      height: 35,
    },
    {
      src: imgClients5,
      width: "311px",
      height: 50,
    },
    {
      src: imgClients3,
      width: 123,
      height: 25,
    },
    {
      src: imgClients2,
      width: 260,
      height: 12,
    },
    {
      src: imgClients6,
      width: 428,
      height: 20,
    },
    {
      src: imgClients4,
      width: 80,
      height: 80,
    },
    {
      src: imgClients8,
      width: 80,
      height: 50,
    },
    {
      src: imgClients7,
      width: 80,
      height: 30,
    },
  ];

  const industries = [
    {
      image: imgIndustries1,
      title: "homePage.paragraf3.items.item1",
      titleWidth: "126px",
    },
    {
      image: imgIndustries2,
      title: "homePage.paragraf3.items.item2",
      titleWidth: "158px",
    },
    {
      image: imgIndustries3,
      title: "homePage.paragraf3.items.item3",
      titleWidth: "158px",
    },
    {
      image: imgIndustries4,
      title: "homePage.paragraf3.items.item4",
      titleWidth: "144px",
    },
    {
      image: imgIndustries5,
      title: "homePage.paragraf3.items.item5",
      titleWidth: "122px",
    },
  ];

  return (
    <>
      <Section bgColor="white">
        <HomeBanner />
      </Section>
      <Section
        bgColor="dark"
        titleAlignLeft={false}
        margin={isMobile || isTablet ? "64px 0px 32px" : "160px 0px 0px"}
        paddingBottom={isMobile || isTablet ? "3px" : "28px"}
        cardTitle={
          <Trans
            t={t}
            i18nKey="homePage.paragraf1.header"
            components={[<TitleHighlight isStyled={true} key={0} />]}
          />
        }
      >
        <ServiceLineUp />
      </Section>
      <Section
        sx={{ pt: "20px" }}
        bgColor="white"
        margin={isMobile || isTablet ? "64px 0px 20px" : "160px 0px 0px"}
        paddingBottom={isMobile || isTablet ? "10px" : "25px "}
        titleAlignLeft
        cardTitle={
          <Trans
            t={t}
            i18nKey="homePage.paragraf2.header"
            components={[<TitleHighlight isStyled={true} key={0} />]}
          />
        }
      >
        <Box
          sx={{ pb: { xs: 2, md: "100px" }, pt: { xs: "12px", lg: "14px" } }}
        >
          <ScrollingBanner
            images={homeExpertTechStack}
            width={isMobile || isTablet ? "955px" : "3820px"}
          ></ScrollingBanner>
        </Box>
      </Section>
      <Section
        bgColor="dark"
        titleAlignLeft={false}
        margin={isMobile || isTablet ? "64px 0px 32px" : "160px 0px 40px"}
        paddingBottom={isMobile || isTablet ? "3px" : "25px"}
        cardTitle={
          <Trans
            t={t}
            i18nKey="homePage.paragraf3.header"
            components={[<TitleHighlight isStyled={true} key={0} />]}
          />
        }
      >
        <Box sx={{ overflow: "auto" }}>
          <ImageWithTitleBox>
            {industries.map((industry, i) => (
              <ImageWithTitle key={i} $image={industry.image}>
                <ImageTitle $width={industry.titleWidth}>
                  {t(industry.title)}
                </ImageTitle>
              </ImageWithTitle>
            ))}
          </ImageWithTitleBox>
        </Box>
      </Section>
      <Section
        bgColor="white"
        titleAlignLeft
        margin={isMobile || isTablet ? "64px 0px 32px" : "120px 0px 80px"}
        paddingBottom={isMobile || isTablet ? "3px" : "46px"}
        lineHeight={isMobile || isTablet ? "20px" : "107.5px"}
        cardTitle={
          <Trans
            t={t}
            i18nKey="homePage.paragraf4.header"
            components={[<TitleHighlight isStyled={true} key={0} />]}
          />
        }
      >
        <HomeAgileProcess />
      </Section>
      <Section
        bgColor="dark"
        titleAlignLeft={false}
        margin={isMobile || isTablet ? "64px 0px 32px" : "120px 0px 10px"}
        paddingBottom={isMobile || isTablet ? "3px" : "46px"}
        lineHeight={isMobile || isTablet ? "20px" : "110%"}
        cardTitle={
          <Trans
            t={t}
            i18nKey="homePage.paragraf5.header"
            components={[<TitleHighlight isStyled={true} key={0} />]}
          />
        }
      >
        {!(isMobile || isTablet) && (
          <ScrollingBanner
            images={homeClients}
            bgColor="dark"
            width={"5092px"}
          ></ScrollingBanner>
        )}
        {(isMobile || isTablet) && (
          <HomeOurClientsLogos>
            {homeClientsSm &&
              homeClientsSm.map((item, index) => (
                <HomeOurClientsLogo
                  key={index}
                  src={item.src}
                  height={item.height}
                ></HomeOurClientsLogo>
              ))}
          </HomeOurClientsLogos>
        )}
        <HomeOurClients></HomeOurClients>
      </Section>

      <Section
        bgColor="white"
        titleAlignLeft
        margin={isMobile || isTablet ? "64px 0px 32px" : "120px 0px 40px"}
        paddingBottom={isMobile || isTablet ? "5px" : "46px"}
        cardTitle={
          <Trans
            t={t}
            i18nKey="homePage.paragraf6.header"
            components={[<TitleHighlight isStyled={false} key={0} />]}
          />
        }
      >
        <GoogleMap />
      </Section>
    </>
  );
};

export default HomePage;

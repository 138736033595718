import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  HomeOurClientsContainer,
  HomeOurClientsCards,
  HomeOurClientsBtn,
  HomeOurClientsBtnBox,
  HomeOurClientsFade,
} from "./HomeOurClients.styled";
import { useTranslation, Trans } from "react-i18next";
import useDevice from "hooks/useDevice";
import ClientCard from "components/Cards/ClientCard/ClientCard";

//Clients
import imgPersion1 from "assets/images/pages/home/clients/GBI-Peter Custer.jpg";
import imgPersion2 from "assets/images/pages/home/clients/BP-Sasa.jpg";
import imgPersion3 from "assets/images/pages/home/clients/Badin-BIgnjatovic.jpg";
import imgPersion4 from "assets/images/pages/home/clients/Nis ekspres-Adis Terzic.jpg";
import imgPersion5 from "assets/images/pages/home/clients/TS-Antonsson.jpg";

//Clients
import imgBusiness1 from "assets/images/pages/home/clients/gbi_logo.png";
import imgBusiness2 from "assets/images/pages/home/clients/BP Consulting_logo.png";
import imgBusiness3 from "assets/images/pages/home/clients/BadinSoft_logo.png";
import imgBusiness4 from "assets/images/pages/home/clients/Nis Ekspres_logo.png";
import imgBusiness5 from "assets/images/pages/home/clients/Travel Support_ logo.png";
import imgBusiness6 from "assets/images/pages/home/clients/Asseco_logo.png";

import { ReactComponent as ArrowRight } from "assets/images/svg/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/images/svg/arrow-left.svg";

const STEP_SIZE = 535;
const TESTIMONIALS_WIDTH = 2000; //2338
const TESTIMONIAL_WIDTH = 502;
const TESTIMONIALS_MAXWIDTH = 1180;

const HomeOurClients = (props) => {
  const { t } = useTranslation();
  const slader = useRef();
  const { isMobile, isTablet } = useDevice();
  const ourClients = [
    {
      persionName: "homePage.paragraf5.items.item1.persion.name",
      persionPosition: (
        <Trans
          t={t}
          i18nKey="homePage.paragraf5.items.item1.persion.position"
        />
      ),
      message: "homePage.paragraf5.items.item1.text",
      persionImg: imgPersion1,
      businessImg: imgBusiness1,
      businessImgHeight: "55px",
      businessImgHeightSm: "35px",
    },
    {
      persionName: "homePage.paragraf5.items.item2.persion.name",
      persionPosition: (
        <Trans
          t={t}
          i18nKey="homePage.paragraf5.items.item2.persion.position"
        />
      ),
      message: "homePage.paragraf5.items.item2.text",
      persionImg: imgPersion2,
      businessImg: imgBusiness2,
      businessImgHeight: "35px",
      businessImgHeightSm: "22px",
    },
    {
      persionName: "homePage.paragraf5.items.item3.persion.name",
      persionPosition: (
        <Trans
          t={t}
          i18nKey="homePage.paragraf5.items.item3.persion.position"
        />
      ),
      message: "homePage.paragraf5.items.item3.text",
      persionImg: imgPersion3,
      businessImg: imgBusiness3,
      businessImgHeight: "55px",
      businessImgHeightSm: "35px",
    },
    {
      persionName: "homePage.paragraf5.items.item4.persion.name",
      persionPosition: (
        <Trans
          t={t}
          i18nKey="homePage.paragraf5.items.item4.persion.position"
        />
      ),
      message: "homePage.paragraf5.items.item4.text",
      persionImg: imgPersion4,
      businessImg: imgBusiness4,
      businessImgHeight: "25px",
      businessImgHeightSm: "20px",
    },
    {
      persionName: "homePage.paragraf5.items.item5.persion.name",
      persionPosition: (
        <Trans
          t={t}
          i18nKey={`homePage.paragraf5.items.item5.persion.position${
            isMobile || isTablet ? "Sm" : ""
          }`}
        />
      ),
      message: "homePage.paragraf5.items.item5.text",
      persionImg: imgPersion5,
      businessImg: imgBusiness5,
      businessImgHeight: "35px",
      businessImgHeightSm: "25px",
    },
    {
      persionName: "homePage.paragraf5.items.item6.persion.name",
      persionPosition: null,
      message: "homePage.paragraf5.items.item6.text",
      persionImg: null,
      businessImg: imgBusiness6,
      businessImgHeight: "10px",
      businessImgHeightSm: "8px",
    },
  ];
  const screenWidth = document.documentElement.clientWidth;
  const [mouseDown, setMouseDown] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const handlePointerMove = (e) => {
    if (mouseDown) {
      setPosition((prev) => ({
        x: Math.max(e.movementX + prev.x, -(TESTIMONIALS_WIDTH + 300)),
        y: e.clientY,
      }));
    } else {
      console.log(e);
    }
  };

  const onMouseUp = () => {
    setMouseDown(false);
    setPosition((prev) => {
      return {
        ...prev,
        x:
          prev.x > 0
            ? 0
            : Math.max(prev.x + (prev.x % 400), -(TESTIMONIALS_WIDTH + 300)),
      };
    });
  };

  const changePostionLeft = () => {
    setMouseDown(false);
    setPosition((prev) => ({
      ...prev,
      x: prev.x + STEP_SIZE,
    }));
  };
  const changePostionRight = () => {
    setMouseDown(false);
    setPosition((prev) => ({
      ...prev,
      x: prev.x - STEP_SIZE,
    }));
  };

  useEffect(() => {
    if (position.x > 0) {
      setPosition((prev) => ({
        ...prev,
        x: 0,
      }));
    } else {
      const testmMinPosition =
        TESTIMONIAL_WIDTH * ourClients.length -
        Math.min(screenWidth, TESTIMONIALS_MAXWIDTH);
      if (position.x < -1 * testmMinPosition) {
        setPosition((prev) => ({
          ...prev,
          x: -1 * testmMinPosition,
        }));
      }
    }
  }, [position]);

  return (
    <HomeOurClientsContainer
      onMouseMove={handlePointerMove}
      onMouseDown={() => {
        if (!isMobile && !isTablet) {
          setMouseDown(true);
        }
      }}
    >
      <HomeOurClientsCards
        onMouseUp={onMouseUp}
        ref={slader}
        sx={{
          transform: `translateX(${position.x}px)`,
          transitionDuration: mouseDown ? "0.2s" : "1s",
        }}
      >
        {ourClients?.map((item, index) => {
          return (
            <ClientCard
              key={index}
              persionName={t(item.persionName)}
              persionPosition={item.persionPosition}
              message={t(item.message)}
              persionImg={item.persionImg}
              businessImg={item.businessImg}
              businessImgHeight={
                isMobile || isTablet
                  ? item.businessImgHeightSm
                  : item.businessImgHeight
              }
            ></ClientCard>
          );
        })}
      </HomeOurClientsCards>
      <HomeOurClientsFade onMouseUp={onMouseUp}></HomeOurClientsFade>

      {!isMobile && !isTablet && (
        <HomeOurClientsBtnBox>
          <HomeOurClientsBtn onClick={changePostionLeft}>
            <ArrowLeft />
          </HomeOurClientsBtn>
          <HomeOurClientsBtn onClick={changePostionRight}>
            <ArrowRight />
          </HomeOurClientsBtn>
        </HomeOurClientsBtnBox>
      )}
      {props.children}
    </HomeOurClientsContainer>
  );
};

HomeOurClients.propTypes = {
  children: PropTypes.node,
};

HomeOurClients.defaultProps = {
  width: "1500px",
};

export default HomeOurClients;

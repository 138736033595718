import React from "react";
import { Iframe } from "./GoogleMap.style";
function GoogleMap() {
  return (
    <Iframe
      src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(Tremium%20Software)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      width="100%"
      height="450"
      allowfullscreen=""
      loading="lazy"
    />
  );
}
export default GoogleMap;

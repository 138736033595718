import { Grid } from "@mui/material";
import styled from "styled-components";
import { ImageElement } from "components/Image/Image.styled";

export const SubSectionContainer = styled(Grid)`
  text-align: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: ${(props) =>
    props?.marginTop !== undefined && props.marginTop !== ""
      ? props.marginTop
      : "12px"};
  margin-bottom: 98px;
  &:last-of-type {
    margin-bottom: 65px;
  }
  @media (max-width: 1126px) {
    flex-direction: ${(props) =>
      props.$reverse ? "column-reverse" : "column"};
  }
  @media (max-width: 768px) {
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 5px;
    }
  }
`;
export const SubSectionContainerText = styled.div`
  text-align: left;
  height: 100%;
  font-size: 24px;
  line-height: 36px;
  max-width: 556px;
  color: ${(props) => props?.$textColor};
  @media (max-width: 900px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    line-height: 150%;
    margin-bottom: 64px;
    &:last-of-type {
      margin-bottom: 36px;
    }
  }
`;
export const InnerSubSection = styled.div`
  display: flex;
  flex-direction: row;
  & img {
    max-height: ${(props) =>
      props.height !== undefined && props.height !== ""
        ? props.height
        : "420px"};
    max-width: ${(props) =>
      props.height === undefined && props.height === "" ? "auto" : "550px"};
  }
  @media (max-width: 768px) {
    & ${ImageElement} {
      height: 300px;
      width: 100%;
    }
  }
`;

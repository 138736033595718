import styled, { keyframes, css } from "styled-components";
import { Box } from "@mui/material";

const DEFAULT_SPEED = 40;
const DEFAULT_WIDTH = 3820;

const loopAnimation1 = keyframes`
    0% {transform: translateX(calc(100%));}
    100% {transform: translateX(calc(-100%));}
`;

const loopAnimationInit = keyframes`
    0% {transform: translateX(calc(0));}
    100% {transform: translateX(calc(-100%));}
`;

export const ScrollingBannerContener = styled(Box)`
  margin: 50px 0px 60px;
  width: 100%;
  overflow: hidden;
  min-height: 60px;
`;

export const ScrollingBannerScroll = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 0;
  height: 100%;
  width: ${(props) => props.$width ?? DEFAULT_WIDTH};
  position: relative;
  top: 0;

  ${(props) => {
    const speed =
      (DEFAULT_SPEED * +props.$width.replace("px", "")) / DEFAULT_WIDTH;
    return props.$scrollTwo
      ? css`
          animation: ${loopAnimation1} ${speed}s linear infinite;

          margin-top: -120px;
          transform: translateX(100%);

          @media (max-width: 900px) {
            margin-top: -60px;
          }
        `
      : props?.$initAnimation
      ? css`
          animation: ${loopAnimationInit} ${speed / 2}s linear forwards;
        `
      : css`
          transform: translateX(100%);
          animation: ${loopAnimation1} ${speed}s linear infinite;
        `;
  }};
`;

export const ScrollingBannerItem = styled.img`
  margin: 0rem 1rem;
  max-height: ${(props) => props.height};
  max-width: ${(props) => props.width};
  width: auto;
  height: auto;
`;

export const ScrollingBannerFade = styled(Box)`
  width: 100%;
  position: relative;
  background: linear-gradient(
    90deg,
    ${(props) => props?.theme.colors[props?.$bgColor]},
    transparent 20%,
    transparent 90%,
    ${(props) => props?.theme.colors[props?.$bgColor]}
  );
  top: 0;
  height: 120px;
  z-index: 1050;

  margin-top: -120px;
  @media (max-width: 900px) {
    height: 60px;
    margin-top: -60px;
  }
`;
